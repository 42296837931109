import * as React from 'react';
import ReactDOM from 'react-dom';

import PageNotFoundView from 'ms-components/PageNotFoundView';
import PageViewTracker from 'ms-helpers/PageViewTracker';
import RootErrorBoundary from 'ms-helpers/RootErrorBoundary';
import { SnowplowProvider } from 'ms-helpers/Snowplow/SnowplowProvider';
import { PublicEnvironmentProvider } from 'ms-utils/relay';

type Props = {
  friendlyErrorMessage?: string | null | undefined;
};

// @ts-expect-error Don't want to expose snowplow on Window interface
const snowplow = window.snowplow;

const NotFound = ({ friendlyErrorMessage }: Props) => (
  <RootErrorBoundary>
    <PublicEnvironmentProvider>
      <SnowplowProvider snowplow={snowplow}>
        <PageViewTracker />
        <PageNotFoundView friendlyErrorMessage={friendlyErrorMessage} />
      </SnowplowProvider>
    </PublicEnvironmentProvider>
  </RootErrorBoundary>
);

if (window.INIT) window.INIT(NotFound, React, ReactDOM);

export default NotFound;
